import swIcon1 from '../images/service/icon-1.png'
import swIcon2 from '../images/service/icon-2.png'
import swIcon3 from '../images/service/icon-3.png'
import swIcon4 from '../images/service/icon-4.png'
import swIcon5 from '../images/service/icon-5.png'
import swIcon6 from '../images/service/icon-6.png'




const Services = [
    {
        Id: '1',
        sIcon:swIcon1,
        title: 'AI-Driven Development',
        slug: 'Web-UI-UX-Design',
        description:'Crafting adaptive software with advanced AI algorithms for smart solutions',
        des2:'We are helping client to create WordPress websites.We are helping client to create UI websites.',
    },
    {
        Id: '2',
        sIcon:swIcon2,
        title: 'Full Stack Development',
        slug: 'Mobile-UI-UX-Design',
        description:'Complete development services from robust backends to dynamic user interfaces',
        des2:'We are helping client to create WordPress websites.We are helping client to create UI websites.',
    },
    {
        Id: '3',
        sIcon:swIcon3,
        title: 'Data Engineering & Analytics',
        slug: 'Branding-Design',
        description:'Transforming raw data into actionable insights for strategic decision-making',
        des2:'We are helping client to create WordPress websites.We are helping client to create UI websites.',
    },
    {
        Id: '4',
        sIcon:swIcon4,
        title: 'Cloud Computing Solutions',
        slug: 'Creative-Design',
        description:'Scalable, cloud-based infrastructures for modern application demands',
        des2:'We are helping client to create WordPress websites.We are helping client to create UI websites.',
    },
    {
        Id: '5',
        sIcon:swIcon5,
        title: 'Cybersecurity Innovation',
        slug: 'Movement',
        description:'Advanced AI security measures to protect and secure your digital landscape',
        des2:'We are helping client to create WordPress websites.We are helping client to create UI websites.',
    },
    {
        Id: '6',
        sIcon:swIcon6,
        title: 'IoT & Smart Technology',
        slug: 'Movement',
        description:'Integrating IoT for seamless connectivity and smarter device interactions',
        des2:'We are helping client to create WordPress websites.We are helping client to create UI websites.',
    },
    // {
    //     Id: '7',
    //     sIcon:swIcon5,
    //     title: 'Blockchain Development',
    //     slug: 'Movement',
    //     description:'Creating decentralized applications with blockchain for enhanced security and trust',
    //     des2:'We are helping client to create WordPress websites.We are helping client to create UI websites.',
    // },
]    

export default Services;