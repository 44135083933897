import React from 'react';
import { Link } from 'react-router-dom';
import BlogSidebar from '../BlogSidebar';
import blogs from '../../api/blogs';

const BlogList = (props) => {
    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }

    return (
        <section className="blog-page-area section-padding">
            <div className="container">
                <div className="row">
                    <div className={`col col-lg-8 col-md-12 col-12 ${props.blRight}`}>
                        <div className="blog-page-left">
                            {blogs.slice(0, 4).map((blog, bitem) => (
                                <div className="blog-item" key={bitem}>
                                    <div className="blog-img">
                                        <img src={blog.bSingle} alt="" />
                                    </div>
                                    <div className="blog-content">
                                        <span>Published {blog.create_at}</span>
                                        <h2>
                                            {/* Changed to use state for passing data */}

                                            <Link onClick={ClickHandler} to='/blog-single'  // Using ID or another unique identifier in the URL for consistency
                                                state={{ blog: blog }}
                                                className="read-more"> {blog.title}</Link>

                                        </h2>
                                        <p>{blog.description}</p>
                                        {/* Changed to use state for passing data */}
                                        <Link onClick={ClickHandler} to='/blog-single'  // Using ID or another unique identifier in the URL for consistency
                                            state={{ blog: blog }}
                                            className="read-more">Read More</Link>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    <BlogSidebar blLeft={props.blLeft} />
                </div>
            </div>
        </section>
    );
}

export default BlogList;
