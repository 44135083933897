import blogImg1 from "../images/blog/img-1.jpg";
import blogImg2 from "../images/blog/img-2.jpg";
import blogImg3 from "../images/blog/img-3.jpg";
import blogImg4 from "../images/blog/img-4.jpg";
import blogImg5 from "../images/blog/img-5.jpg";
import blogImg6 from "../images/blog/img-6.jpg";

import blogsImg1 from "../images/blog/blog-single/img-1.jpg";
import blogsImg2 from "../images/blog/blog-single/img-2.jpg";
import blogsImg3 from "../images/blog/blog-single/img-3.jpg";
import blogsImg4 from "../images/blog/blog-single/img-4.jpg";
import blogsImg5 from "../images/blog/blog-single/img-5.jpg";
import blogsImg6 from "../images/blog/blog-single/img-6.jpg";

const blogs = [
    {
        id: '1',
        title: 'The Future of AI in Web Development',
        slug: 'the-future-of-ai-in-web-development',
        screens: blogImg1,
        bSingle: blogsImg1,
        description: 'Explore how AI is revolutionizing web development, from automated design to personalized user experiences.',
        author: 'Jordan Smith',
        authorTitle: 'Lead AI Researcher',
        create_at: 'March 5, 2024',
        comment: '45',
        blClass: 'format-standard-image',
    },
    {
        id: '2',
        title: 'Best Practices in Modern Software Development',
        slug: 'best-practices-in-modern-software-development',
        screens: blogImg2,
        bSingle: blogsImg2,
        description: 'Discover the latest best practices in software development, including agile methodologies, DevOps, and continuous integration.',
        author: 'Alex Johnson',
        authorTitle: 'Software Architect',
        create_at: 'March 12, 2024',
        comment: '30',
        blClass: 'format-standard-image',
    },
    {
        id: '3',
        title: 'How AI is Transforming Customer Service',
        slug: 'how-ai-is-transforming-customer-service',
        screens: blogImg3,
        bSingle: blogsImg3,
        description: 'Learn about the impact of AI on customer service, from chatbots to AI-driven support systems.',
        author: 'Emily Davis',
        authorTitle: 'Customer Experience Manager',
        create_at: 'April 1, 2024',
        comment: '50',
        blClass: 'format-video',
    },
    {
        id: '4',
        title: 'The Role of Machine Learning in Data Analysis',
        slug: 'the-role-of-machine-learning-in-data-analysis',
        screens: blogImg4,
        bSingle: blogsImg4,
        description: 'Dive into how machine learning algorithms are used in data analysis to uncover insights and predict trends.',
        author: 'Michael Brown',
        authorTitle: 'Data Scientist',
        create_at: 'April 15, 2024',
        comment: '60',
        blClass: 'format-video',
    },
    {
        id: '5',
        title: 'Emerging Technologies in Software Engineering',
        slug: 'emerging-technologies-in-software-engineering',
        screens: blogImg5,
        bSingle: blogsImg5,
        description: 'An overview of emerging technologies in software engineering, including blockchain, IoT, and more.',
        author: 'Sarah Wilson',
        authorTitle: 'Tech Innovator',
        create_at: 'April 22, 2024',
        comment: '75',
        blClass: 'format-video',
    },
    {
        id: '6',
        title: 'Integrating AI into Your Business Strategy',
        slug: 'integrating-ai-into-your-business-strategy',
        screens: blogImg6,
        bSingle: blogsImg6,
        description: 'Strategies for businesses looking to integrate AI into their operations to enhance efficiency and innovation.',
        author: 'David Lee',
        authorTitle: 'Business Strategist',
        create_at: 'May 5, 2024',
        comment: '85',
        blClass: 'format-video',
    },
];

export default blogs;
