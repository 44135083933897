import React, { Fragment } from 'react';
import Header2 from '../../components/Header2/Header2';
import PageTitle from '../../components/pagetitle/PageTitle'
import PartnerSection from '../../components/PartnerSection';
import Scrollbar from '../../components/scrollbar/scrollbar'
import Footer from '../../components/footer/Footer';
import { Link } from 'react-router-dom'
import Services from '../../api/service'
import aImg from '../../images/about2.png'
import ProjectSectionS2 from '../../components/ProjectSectionS2/ProjectSectionS2';
import NewsLatter from '../../components/NewsLatter/NewsLatter';
import BannerVideo from "../../video/aboutusbanner.mp4"
const ClickHandler = () => {
    window.scrollTo(10, 0);
}


const AboutUsPage = (props) => {

    return (
        <Fragment>
            <Header2 hclass={'header-style-3'} />
            <PageTitle pageTitle={'About Us'} pagesub={'about'} />
            <section className="about-us-page section-padding pb-0">
                <div className="container">
                    <div className="about-page-top-video">
                        {/* <img className="imageParallax" src={aImg} alt="" /> */}
                        <video className="videoParallax" autoPlay loop muted>
                            <source src={BannerVideo} type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>
                    </div>
                </div>
                <div className="service-section section-padding" id="case">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <h2>Why Choose WebFD AI?</h2>
                                <p><strong>Innovation at Its Core</strong></p>
                                <p>At WebFD AI, innovation isn't just a buzzword—it's our ethos. With a relentless drive to explore the frontier of technology, we provide revolutionary AI solutions that are as unique as your needs. Our commitment to innovation ensures that we're not just following trends; we're creating them.</p>
                                <p><strong>Customized for Your Success</strong></p>
                                <p>What makes your game unique? The same thing that makes us unique: a tailored approach. We understand that no two projects are the same, and we pride ourselves on crafting personalized strategies that highlight the distinctive features of your business and set you apart from the rest.</p>
                                <p><strong>Expertise Meets Creativity</strong></p>
                                <p>Our team is a blend of seasoned experts and creative mavericks. From precise analytics to imaginative problem-solving, we bring the best of both worlds to every project. We don't just build solutions; we engineer experiences that resonate with your audience.</p>
                                <p><strong>Future-Ready Technology</strong></p>
                                <p>Why does it stand out from the rest? Because it's built to not only meet the current demands but also to anticipate the future. Our AI-driven strategies are designed with adaptability in mind, ensuring that your business thrives today and is ready for the challenges of tomorrow.</p>
                                <p><strong>Partnership and Growth</strong></p>
                                <p>We believe in growing with our clients. Your success is our success. Choosing us means entering into a partnership that values your vision, with a dedicated team committed to turning your goals into achievements.</p>
                                <p><strong>Excellence in Execution</strong></p>
                                <p>With WebFD AI, you're guaranteed an unwavering commitment to excellence. Every line of code, every user interface, and every strategy is executed with precision, ensuring that your project not only meets but exceeds expectations.</p>
                            </div>

                        </div>
                    </div>
                </div>
                {/* <PartnerSection pClass={'black-bg'} /> */}
                {/* <ProjectSectionS2/> */}
            </section>
            {/* <NewsLatter /> */}
            <Footer />
            <Scrollbar />
        </Fragment>
    )
};
export default AboutUsPage;
