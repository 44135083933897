import React, { Fragment, useEffect } from 'react';
import Header2 from '../../components/Header2/Header2';
import PageTitle from '../../components/pagetitle/PageTitle';
import Scrollbar from '../../components/scrollbar/scrollbar';
import { useLocation } from 'react-router-dom';
import Footer from '../../components/footer/Footer';
import Projects from '../../api/project';

const ProjectSinglePage = () => {
    const location = useLocation(); // Use useLocation hook to access location object
    const { project } = location.state; // Access the project object from state

    // Find the project details corresponding to the slug
    const practiceDetails = Projects.find(item => item.slug === project.slug);
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    // Function to render objectives
    const renderObjectives = (objectives) => {
        return objectives.map((obj, index) => (
            <div key={index} style={{ marginBottom: '20px' }}>
                <h4>{obj.title}</h4>
                <p>{obj.description}</p>
            </div>
        ));
    };

    return (
        <Fragment>
            <Header2 hclass={'header-style-3'} />
            <div className="porfolio-single-page">
                <div className="portfolio-details-wrap section-padding">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-7">
                                <div className="portfolio-details-img">
                                    <img src={practiceDetails?.psImg} alt="" style={{ maxWidth: '70%', height: 'auto' }} />
                                </div>
                            </div>
                            <div className="col-lg-5">
                                <div className="portfolio-details-text">
                                    <div style={{ fontSize: 50 , paddingBottom:50}}>{practiceDetails?.title}</div>
                                    {practiceDetails?.singlepageData?.objectives && renderObjectives(practiceDetails.singlepageData.objectives)}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
            <Scrollbar />
        </Fragment>
    );
};

export default ProjectSinglePage;
