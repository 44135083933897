import React from 'react';
import star from '../../images/star.png';

const MarqueeSection = (props) => {
    return (
        <div className="animate-marque-sec">
            <div className="animate-marque">
                <div className="marquee">
                    <div className="track">
                        <div className="content">
                            <h1>
                                <span>AI-Driven Development</span> <i><img src={star} alt=""/></i>
                                <span>Full Stack Development</span> <i><img src={star} alt=""/></i>
                                <span>Data Engineering & Analytics</span> <i><img src={star} alt=""/></i>
                                <span>Cloud Computing Solutions</span> <i><img src={star} alt=""/></i>
                                <span>Cybersecurity Innovation</span> <i><img src={star} alt=""/></i>
                                <span>IoT & Smart Technology</span> <i><img src={star} alt=""/></i>
                                <span>Blockchain Development</span> <i><img src={star} alt=""/></i>
                            </h1>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default MarqueeSection;
