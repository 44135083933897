import React, { Fragment } from 'react';
import { useLocation } from 'react-router-dom'; // Import useLocation
import Header2 from '../../components/Header2/Header2';
import PageTitle from '../../components/pagetitle/PageTitle';
import Scrollbar from '../../components/scrollbar/scrollbar';
import BlogSingle from '../../components/BlogDetails/BlogDetails';
import Footer from '../../components/footer/Footer';

const BlogDetails = () => {
    const location = useLocation(); // Use useLocation to access the location object
    const { blog } = location.state || {}; // Destructure the blog object from location.state

    console.log(blog)

    return (
        <Fragment>
            <Header2 hclass={'header-style-3'}/>
            <PageTitle pageTitle={blog ? blog.title : 'Blog Details'} pagesub={blog ? 'Blog' : 'Blog'} />
            {/* Pass the blog object as a prop to BlogSingle */}
            <BlogSingle blog={blog} blLeft={'d-none'} blRight={'col-lg-12'}/>
            <Footer />
            <Scrollbar />
        </Fragment>
    );
};

export default BlogDetails;
